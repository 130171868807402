import React, { Component } from "react"
import classnames from "classnames"
import UserContextHOC from "../contexts/UserContextHOC"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import StripeTerminalContextHOC from "../contexts/StripeTerminalContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import {registerPlugin} from '@capacitor/core';
import Loading from "../Loading/Loading"
import "./Home.css"
//const Api = require("../api")
//const StripeReaderPlugin = registerPlugin('StripeReaderPlugin');
import {
  StripeTerminalPlugin,
  DiscoveryMethod
} from 'capacitor-stripe-terminal'


class StripePOSHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded:false
    }
  }

  componentDidMount(){
    if(this.props.user.stripeTerminal){
      this.props.history.replace({
        pathname:'/stripe-pos/connected'
      })
    } else{
      this.setState({loaded:true})
    }
  }
  
  async discoverReaders(){
    /*
    this.terminal
      .discoverReaders({
        simulated: false,
        discoveryMethod: DiscoveryMethod.BluetoothProximity
      })
      .subscribe(readers => {
        if (readers.length) {
          const selectedReader = readers[0]
          console.log("readers", readers[0])
          alert(JSON.stringify(readers[0]))
          const connectionConfig = {
            locationId: '{{LOCATION_ID}}'
          }
          return
          terminal
            .connectBluetoothReader(selectedReader, connectionConfig)
            .then(connectedReader => {
              // the reader is now connected and usable
            })
          alert("found readers")
        }
      })
      */
  }

  async checkIfConnected(){
    /*
    let ret = await StripeReaderPlugin.isConnected()
    let connected = ret['connected']
    if(connected == 1){
      this.props.history.replace({
        pathname:'/stripe-pos/connected'
      })
    } else{
      // try to reconnect to one if saved 
      if(this.props.user.stripeTerminal){
        let result = await StripeReaderPlugin.connectToReader({name:this.props.user.stripeTerminal})
        if (result['success'] == 1){
          this.props.history.replace({
            pathname:'/stripe-pos/connected'
          })
          return
        }
      }


      this.setState({loaded:true})
    }*/
    this.setState({loaded:true})
  }

  render(){
    if(!this.state.loaded){
      return (
        <div>
          <Loading/>
        </div>
      )
    }
    return (
      <div>
        <div className='stripe-reader-image-container'>
          <img 
            className='stripe-reader-big-image'
            src='https://cdn.hngr.co/captain/stripem2resized.png'
          />
        </div>
        <div className='orders-content'>
          <div className='connect-to-terminal-title'>
            Connect to a Stripe Terminal
          </div>
          <div className='connect-to-terminal-blurb'>
            Process credit cards on site.
          </div>

          <div 
            className='connect-to-terminal-button'
            onClick={async () => {
              this.props.history.push({
                pathname:'/stripe-pos/choose'
              })
            }}
          >
            <div>Connect to Terminal</div>
          </div>
        </div>
      </div>

    )
  }

}
      
export default withRouterAndRef(
  UserContextHOC(StripeTerminalContextHOC(StripePOSHome)))


