import React, { Component } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
//import Bell from "./svg/Bell"
import Terminal from "./svg/Terminal"
import CaptainLogo from "./svg/CaptainLogo"
import ChevronLeft from "./svg/ChevronLeft"
import classnames from 'classnames'
import OrderNavigationPills from "./OrderNavigationPills"
import Warning from "./svg/Warning"
import "./Header.css"
import {Capacitor, registerPlugin} from '@capacitor/core';
const StripeReaderPlugin = registerPlugin('StripeReaderPlugin');


class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unconfirmedOrders:[],
      isDesktop:window.innerWidth >= 1000,
      isTablet:window.innerWidth >= 600,
      isMobile:window.innerWidth < 600,
      isUnconfirmedPage:false,
      pathname:this.props.location.pathname,
      pickupClosedEarly:false,
      deliveryClosedEarly:false
    }
    this.openUnconfirmed = this.openUnconfirmed.bind(this)
    window.H = this
  }


  componentDidUpdate(prevProps){
    if(
      this.state.unconfirmedOrders !== 
      this.props.unconfirmedOrders.unconfirmedOrders
    ){
      this.setState({unconfirmedOrders:this.props.unconfirmedOrders.unconfirmedOrders})
    }

    if(this.state.pathname !== this.props.location.pathname){
      this.setState({pathname:this.props.location.pathname})
    }

    if(
      this.props.location.pathname.indexOf('unconfirmed') > -1 &&
      !this.state.isUnconfirmedPage
    ){
      this.setState({isUnconfirmedPage:true})
    }

    if(
      this.props.location.pathname.indexOf('unconfirmed') === -1 &&
      this.state.isUnconfirmedPage
    ){
      this.setState({isUnconfirmedPage:false})
    }

    if(this.props.orders.pickupClosedEarly !== this.state.pickupClosedEarly){
      this.setState({pickupClosedEarly:this.props.orders.pickupClosedEarly})
    }

    if(this.props.orders.deliveryClosedEarly !== this.state.deliveryClosedEarly){
      this.setState({deliveryClosedEarly:this.props.orders.deliveryClosedEarly})
    }
  }

  openUnconfirmed(e){
    if (e.target.className === 'header-icon-container') return
    if (e.target.className === 'header-back') return
    if (e.target.hasAttribute("isback")) return
    if (e.target.hasAttribute("isTerminal")) return


    
    if(this.state.isUnconfirmedPage) return
    this.props.history.push({
      pathname:'/orders/unconfirmed'
    })
  }

  openTerminal(){
    this.props.history.push({
      pathname:'/stripe-pos/create-order'
    })
  }


  // confusing code because some stuff goes back to a specific path
  // and others just history.pop
  renderBackButton(){
    // hacky motherfucking special cases where we're dealing with query
    // params and match params. Sprinting to get this done. Sorry it sucks
    if(this.state.pathname.indexOf('/menu/modifier-class-from-menu-page/') > -1){
      let parts = this.state.pathname.split('/')
      let menu_uuid = parts[3]
      let back = '/menu/modifiers/'+menu_uuid
      return (
        <div 
          className='header-icon-container'
          onClick={()=>{
            this.props.history.push({pathname:back})
          }}
        >
          <ChevronLeft isback='1' className='header-back' />
        </div>
      )   
    }

    if(this.state.pathname.indexOf('/menu/modifier-class-from-menu-item') > -1){
      let parts = this.state.pathname.split('/')
      let menu_item_id = parts[4]
      let menu_uuid = parts[3]
      let back = '/menu/menu-item/'+menu_uuid +'/' + menu_item_id
      return (
        <div 
          className='header-icon-container'
          onClick={()=>{
            this.props.history.push({pathname:back})
          }}
        >
          <ChevronLeft isback='1' className='header-back' />
        </div>
      )   
    }

    if(
      this.state.pathname.indexOf('/menu/menu-item') > -1 &&
      this.state.pathname.indexOf('/menu/menu-item-edit') === -1 
    ){
      let parts = this.state.pathname.split('/')
      let menu_uuid = parts[3]
      let back = '/menu/menu/'+menu_uuid
      return (
        <div 
          className='header-icon-container'
          onClick={()=>{
            this.props.history.push({pathname:back})
          }}
        >
          <ChevronLeft isback='1' className='header-back' />
        </div>
      )   
    }
    
    let partialPathsWithDefinedRedirect = [
      {path:'/menu/menu/', back:'/menu/menus'},
      {path:'/menu/modifiers/', back:'/menu/menus'},
      {path:'/menu/menu-categories', back:'/menu/menus'}
    ]
    for(let i in partialPathsWithDefinedRedirect){
      let path = partialPathsWithDefinedRedirect[i].path 
      let back  = partialPathsWithDefinedRedirect[i].back
      if(this.state.pathname.indexOf(path) > -1){
        return (
          <div 
            className='header-icon-container'
            onClick={()=>{
              this.props.history.push({pathname:back})
            }}
          >
            <ChevronLeft isback='1' className='header-back' />
          </div>
        )
      }
    }

    let backButton = (
      <div 
        className='header-icon-container'
        onClick={()=>{
          this.props.history.go(-1)
        }}
      >
        <ChevronLeft isback='1' className='header-back' />
      </div>
    )
    

    let backButtonPaths = [
      '/orders/unconfirmed',
      '/settings/choose-printer',
      '/settings',
      '/tax-exemptions',
    ]
    if(backButtonPaths.indexOf(this.state.pathname) > -1 ){
      return backButton
    }

    let  backButtonPartialPaths = [
      '/menu/modifier-group/create',
      '/menu-item-create',
      '/menu/menu-item/',
      '/menu/menu-item-edit/',
      '/menu/mobile-menu',
      'menu/modifier-class/',
      'modifier-class-edit/',
      'menu-section-create',
      'menu-section-edit'
    ]
    for(var i in backButtonPartialPaths){
      if(this.state.pathname.indexOf(backButtonPartialPaths[i]) > -1){
        return backButton
      }
    }


    return (
      <div className='header-icon-container invisible'></div>
    )
  }

  renderGreyHeader(){
    return (
      <div 
        className={classnames('orders-header-container',
          'no-orders-header')}
      >
        {this.renderBackButton()}
       
        <CaptainLogo className='header-captain-logo'/>
        
        {Capacitor.getPlatform() === 'android' && StripeReaderPlugin  ?   (
          <div className='header-icon-container' onClick={()=>this.openTerminal()}>
            <Terminal className='header-bell white' isTerminal='1'/>
          </div>
        ) : (
          <div className='header-icon-container'>
          </div>
        )}
      </div>
    )
  }

  renderGreenHeader(){
    return (
      <div 
        className={classnames('orders-header-container',
          'yes-orders-header')}
        onClick={this.openUnconfirmed}
      >
        
        {this.renderBackButton()}
       
        <div className='order-header-inner-wrapper'>
          <div className='orders-header-header'>
            {this.state.unconfirmedOrders.length} Incoming Orders 
          </div>
          <div className='orders-header-subheader'>
            {!this.state.isUnconfirmedPage  &&
              <>Tap to Manage Orders </>
            }
          </div>
        </div>


        {Capacitor.getPlatform() === 'android' && StripeReaderPlugin  ?   (
          <div className='header-icon-container' onClick={()=>this.openTerminal()}>
            <Terminal className='header-bell white' isTerminal='1'/>
          </div>
        ) : (
          <div className='header-icon-container'>
          </div>
        )}

      </div>
    )
  }

  renderClosedBanner(){
    if(this.state.pathname.indexOf('orders') === -1) return null

    if(!this.state.pickupClosedEarly && !this.state.deliveryClosedEarly){
      return null
    }

    let text
    if(this.state.pickupClosedEarly && this.state.deliveryClosedEarly){
      text = 'All orders temporarily set to unavailable'
    } else if (this.state.pickupClosedEarly){
      text = 'Pickup orders temporarily set to unavailable'
    } else{
      text = 'Delivery orders temporarily set to unavailable'
    }

    return (
      <div className='closed-banner'>
        <div className='closed-banner-left'>
          <Warning />
          <div>{text}</div>
        </div>
        <div 
          className='closed-banner-change'
          onClick={()=>this.props.history.push({
            pathname:'/settings'
          })}
        >
          Change
        </div>
      </div>
    )
  }

  render(){
    if(this.state.isDesktop) return null
    if(this.state.pathname.indexOf('login') > -1) return null
    return (
      <>
        <div className='header-wrapper'>
          {Capacitor.getPlatform() === 'ios' && (
            <div className='ios-notch-height' style={{width:'100%', zIndex:9, backgroundColor:'white'}}></div>
          )}
          {this.state.unconfirmedOrders.length === 0 && this.renderGreyHeader()}
          {this.state.unconfirmedOrders.length > 0 && this.renderGreenHeader()}
          <OrderNavigationPills />
          {this.renderClosedBanner()}
        </div>
      </>
    )
  }
}
      

export default withRouterAndRef(NewOrdersContextHOC(UserContextHOC(Header))) 


