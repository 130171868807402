import React from 'react';
import { StripeTerminalContext } from './StripeTerminalContext';

export default function StripeTerminalContextHOC(Component) {
  return React.forwardRef((props, ref) => (
    <StripeTerminalContext.Consumer>
      {context => <Component ref={ref} stripeTerminal={context} {...props} />}
    </StripeTerminalContext.Consumer>
  ))
}
