import React, { Component } from 'react';
import { Capacitor } from '@capacitor/core';
import withRouterAndRef from "../withRouterAndRef"
import Loading from "../Loading/Loading"
import { LoadValue, SaveValue } from "../helpers/storage"
import LogRocket from 'logrocket';
const Api = require("../api")

export const UserContext = React.createContext()

class UserProvider extends Component {

  constructor(props){
    super(props)
    this.state = {
      api_key:null,
      user:null,
      userLoaded:false,
      rest:null,
      printer:null,
      receiptCopies:1,
      printTrigger:'transmit',
      loadRest:()=>this.loadRest(false),
      setPrinter:(printer, cb)=>this.setPrinter(printer, cb),
      unsetPrinter:(cb)=>this.unsetPrinter(cb),
      setReceiptCopies:(copies)=>this.setReceiptCopies(copies),
      setPrintTrigger:(trigger)=>this.setPrintTrigger(trigger),
      login:(key)=>this.saveApiKey(key),
      loginAsAdmin:(key)=>this.loginAsAdmin(key),
      loginAsMember:(key, rest_id)=>this.loginAsMember(key, rest_id),
      switchRest:(rest)=>this.switchRest(rest),
      logout:()=>this.logout(),
      isCaptainAdmin:false,
      showAwaiting:false,
      setShowAwaiting:(val)=>this.setShowAwaiting(val),
      pathname:this.props.location.pathname,
      stripeTerminal:null,
      saveStripeTerminal:(terminal)=>this.saveStripeTerminal(terminal),
      disconectStripeTerminal:(cb)=>this.disconectStripeTerminal(cb)
    }
    window.user = this
  }  
  
  componentDidMount(){
    this.loadUser()
    //this.loadIsCaptainAdmin()
   // this.loadApiKey()
    this.loadStripeTerminal()
    this.loadPrinter()
    this.loadReceiptCopies()
    this.loadPrintTrigger()
    this.loadShowAwaiting()
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.pathname !== this.props.location.pathname){
      this.setState({pathname:this.props.location.pathname})
      if(this.props.location.pathname.indexOf('login') === -1){
        this.loadApiKey()
      }
    }

    if(this.state.api_key !== prevState.api_key && this.state.api_key){
      this.recordPushToken(true)
    }
  }


  async setShowAwaiting(val){
    await SaveValue('showAwaiting', val)
    this.setState({showAwaiting:val})
  }

  async loadShowAwaiting(){
    let val = await await LoadValue('showAwaiting')
    if(val){
      this.setState({showAwaiting:true})
    }
  }


  // go ahead and save the push token to the database. If it's not available,
  // then rtry again in 5 seconds... maybe a race condition
  recordPushToken(tryAgain){
    if(window.PUSH_TOKEN){
       Api.callApi({
        endpoint:"save-push-token",
        data:{
          token:window.PUSH_TOKEN,
          os:Capacitor.getPlatform()
        },
        succes:()=>{},
        error:(err)=>{
          console.log("save push error", err)
          setTimeout(()=>this.loadRest(), 30000)
        }
      })
    } else{
      if(tryAgain){
        setTimeout(()=>this.recordPushToken(false), 5000)
      }
    }
    
  }

  async logout(){
    await SaveValue('printer', 0)
    await SaveValue('api_key', 0)
    await SaveValue('isCaptainAdmin', 0)
    await SaveValue('member_rest_id', 0)
    window.API_KEY = null
    this.setState({
      api_key:null,
      user:null,
      userLoaded:true,
      rest:null,
      printer:null,
    }, () => {
      document.location.reload()
    })
  }

  
  async loadPrinter(){
    let val = await await LoadValue('printer')
    if(val){
      this.setState({printer:val})
    }
  }

  async disconectStripeTerminal(cb){
    await SaveValue('stripeTerminal', null)
    this.setState({stripeTerminal:null}, cb)
  }

  async saveStripeTerminal(terminal){
    await SaveValue('stripeTerminal', terminal)
    this.setState({stripeTerminal:terminal})
  }

  async loadStripeTerminal(){
    let val = await await LoadValue('stripeTerminal')
    if(val){
      this.setState({stripeTerminal:val, stripeTerminalLoaded:true})
    } else {
      this.setState({stripeTerminalLoaded:true})
    }

  }

  async setPrinter(printer, callback){
    await SaveValue('printer', printer)
    this.setState({printer:printer}, callback)
  }

  async unsetPrinter(callback){
    await await SaveValue('printer', null)
    this.setState({printer:null}, callback)
  }

  async setReceiptCopies(copies){
    await SaveValue('receiptCopies', copies)
    this.setState({receiptCopies:copies})
  }

  async loadReceiptCopies(){
    let val = await await LoadValue('receiptCopies')
    if(val){
      this.setState({receiptCopies:val})
    }
  }

  async setPrintTrigger(printTrigger){
    await SaveValue('printTrigger', printTrigger)
    this.setState({printTrigger:printTrigger})
  }

  async loadPrintTrigger(){
    let val = await await LoadValue('printTrigger')
    if(val){
      this.setState({printTrigger:val})
    }
  }

  async loadUser(){
    let isAdmin  = await await LoadValue('isCaptainAdmin')
    let memberLogin = await await LoadValue('memberLogin', 1)
    this.setState({isCaptainAdmin:isAdmin, isMemberLogin:memberLogin})
    if(isAdmin){
      window.CAPTAIN_ADMIN = true
    } 
    if (memberLogin) {
      window.IS_MEMBER_LOGIN = true
      window.MEMBER_REST_ID = await await LoadValue('member_rest_id')
      this.loadApiKey()
    } else {
      this.loadApiKey()
    }
  }

  async loadApiKey(dontLoadRest){
    if(window.API_KEY) return
    let api_key = await LoadValue('api_key')
    // if user isn'set, redirect to login page 
    if(!api_key){
      this.setState({userLoaded:true}, ()=> {
        this.props.history.replace({pathname:'/login'})
      })
      return
    } 
    window.API_KEY = api_key
    this.setState({
      userLoaded:true,
      api_key:api_key
    })
    if(!dontLoadRest){
      this.loadRest(true)
    }
  }

  async switchRest(rest){
    //if(window.IS_MEMBER_LOGIN){
      await SaveValue('member_rest_id', rest.restaurant_id)
      window.MEMBER_REST_ID = rest.restaurant_id
    //} else {
    //  await SaveValue('current_restaurant_id', rest.restaurant_id)
    //  window.CAPTAIN_ADMIN_RESTAURANT_ID = rest.restaurant_id
    //}
    this.loadRest(true)
  }

  async saveApiKey(api_key){
    await SaveValue('api_key', api_key)
    window.API_KEY = api_key
    this.setState({api_key:api_key})
    this.loadRest(true)
    this.props.history.replace({pathname:'/orders/kitchen'})
  }


  async loginAsAdmin(api_key){
    await SaveValue('api_key', api_key)
    await SaveValue('isCaptainAdmin', true)
    await SaveValue('memberLogin', 1)
    window.IS_MEMBER_LOGIN = true
    this.setState({api_key:api_key, isCaptainAdmin:true, isMemberLogin:true})
    this.props.history.replace({
      pathname:'/captain-admin/choose-rest'
    })
  }


  async loginAsMember(api_key, rest_id){
    await SaveValue('api_key', api_key)
    await SaveValue('member_rest_id', rest_id)
    await SaveValue('memberLogin', 1)
    window.API_KEY = api_key
    window.IS_MEMBER_LOGIN = true
    window.MEMBER_REST_ID = rest_id
    this.setState({api_key:api_key, isMemberLogin:true})
    this.loadRest(true)
    this.props.history.replace('/')
  }



  async loadIsCaptainAdmin(){
    let val = await await LoadValue('isCaptainAdmin')
    this.setState({isCaptainAdmin:val})
    if(val){
      window.CAPTAIN_ADMIN = true
      window.CAPTAIN_ADMIN_RESTAURANT_ID = await await LoadValue('current_restaurant_id')
      this.loadRest()
    }
  }

  loadRest(recall){
    Api.callApi({
      endpoint:"rest_info",
      success:(data)=>{
        data.rest.restaurant_id = data.rest.id
        this.setState({rest:data.rest})
        console.log("setting rest", data.rest)
        window.REST = data.rest.name_and_secondary
        LogRocket.track(data.rest.name_and_secondary)
        if(recall) setTimeout(()=>this.loadRest(), 30000)
      },
      error:()=>{
        alert('Error loading restaurant')
        if(recall) setTimeout(()=>this.loadRest(), 30000)
      }
    })
  }


  // the second part of if statement is to cover pressing back from login page after
  // logging out 
  renderStuffOrLoading(){
    if(
      !this.state.userLoaded ||
      (this.state.userLoaded  && this.props.location.pathname.indexOf('login') === -1 && !window.API_KEY) 
    ){
      return (
        <div 
          style={{
            position:'absolute', 
            zIndex:999, 
            top:'0px', 
            left:'0px',
            height:'100%',
            width:'100vw',
            backgroundColor:'white',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
          }}
        >
          <Loading/>
        </div>
      )
    } else{
      return this.props.children
    }
  }

  // if the user is not logged in, render the <Login />
  render() {
    return  (
      <UserContext.Provider value={this.state}>
        {this.renderStuffOrLoading()}
      </UserContext.Provider>
    )
  }
}

export default withRouterAndRef(UserProvider);
