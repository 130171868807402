import React, { Component } from "react"
import UserContextHOC from "../contexts/UserContextHOC"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import OrderCard from './OrderCard'
import OrderToast from './OrderToast'
//import ActionButton from "./ActionButton"
import Loading from '../Loading/Loading'
import withRouterAndRef  from "../withRouterAndRef"
import "./Unconfirmed.css"
import "./OrderCard.css"
import { registerPlugin } from '@capacitor/core'
const PrintReceiptPlugin = registerPlugin('PrintReceiptPlugin');

const Api = require("../api")

class UnconfirmedOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      restLoaded:this.props.user.rest,
      unconfirmedOrders:this.props.unconfirmedOrders.unconfirmedOrders,
      ordersLoading:[],
      toast:null,
    }
  }

  componentDidMount(){
    this.props.orders.checkForOrders()
    if(
      this.props.history && 
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.toast
    ){
      this.addToast(this.props.history.location.state.toast)
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(!prevProps.user.rest && this.props.user.rest){
      this.setState({restLoaded:true})
    }
    if(
      this.state.unconfirmedOrders !== 
      this.props.unconfirmedOrders.unconfirmedOrders
    ){
      this.setState({unconfirmedOrders:this.props.unconfirmedOrders.unconfirmedOrders})
    }

    if (
      this.state.unconfirmedOrders.length === 0
    ){
      this.props.history.replace({
        pathname:'/orders/kitchen',
        state:{
          toast:this.state.toast
        }
      })
    }

  }

  addToast(toast){
    this.setState({toast:toast})
    setTimeout(()=>this.removeToast(toast), 4000)
  }

  removeToast(toast){
    if(JSON.stringify(toast) === JSON.stringify(this.state.toast)){
      this.setState({toast:null}) 
    }
  }
  
  confirmOrder(order, callback){
    let order_id = order.order_id 
    let ordersLoading = this.state.ordersLoading

    let prep_time = null
    if(order.pickup_or_delivery === 'pickup'){
      prep_time = this.props.user.rest.pickup_prep_time 
    } else {
      prep_time = this.props.user.rest.delivery_prep_time 
    }

    Api.callApi({
      endpoint:'confirm_order',
      data:{order_id:order_id},
      success:(data)=>{
        if(
          this.props.user.printTrigger === 'confirm' &&
          this.props.user.printer &&
          this.props.user.printer.ipAddress &&
          !this.props.user.rest.fax
        ){
          PrintReceiptPlugin.printReceipt({
            order_id:order_id.toString(),
            source:'confirmation',
            api_key:window.API_KEY,
            ip_address:this.props.user.printer.ipAddress,
            copies:this.props.user.receiptCopies.toString()
          })
        }
        this.props.orders.checkForOrders()
        this.props.orders.checkForActiveOrders()
        this.addToast({
          order:order,
          type:'confirmed',
          prep_time:prep_time
        })
        callback()
      },
      failure:(err)=>{
        console.log("error", err)
        callback()
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error confirming this order.'
          }
        })
      }
    })
  }

  
  snoozeOrder(order, callback){
    let order_id = order.order_id 
    let snooze_until = null
    if(order.pickup_or_delivery === 'pickup'){
      snooze_until = this.props.user.rest.default_pickup_snooze_time
    } else {
      snooze_until = this.props.user.rest.default_delivery_snooze_time
    }

    Api.callApi({
      endpoint:'snooze-order',
      data:{order_id:order_id, snooze_until:snooze_until},
      success:(data)=>{
        if(
          this.props.user.printTrigger === 'confirm' &&
          this.props.user.printer &&
          this.props.user.printer.ipAddress &&
          !this.props.user.rest.fax
        ){
          PrintReceiptPlugin.printReceipt({
            order_id:order_id.toString(),
            source:'confirmation',
            api_key:window.API_KEY,
            ip_address:this.props.user.printer.ipAddress,
            copies:this.props.user.receiptCopies.toString()
          })
        }
        this.props.unconfirmedOrders.checkForOrders()
        this.props.orders.checkForSnoozedOrders()
        this.addToast({
          order:order,
          type:'snoozed'
        })
        if(callback){
          callback()
        }
      },
      failure:(err)=>{
        console.log("waffles", err)
        if(callback){
          callback()
        }
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error confirming this order.'
          }
        })
      }
    })
  }
  
  buttonText(order){
    if(order.fulfill_order_at) return 'Queue'

    let prep_time
    if(order.pickup_or_delivery === 'pickup'){
      prep_time = this.props.user.rest.pickup_prep_time
    } else {
      prep_time = this.props.user.rest.delivery_prep_time
    }
    return `Accept - ${prep_time}m` 
  }
  
  render(){

    if(!this.state.restLoaded){
      return <Loading/>
    }
    return (
      <div className='orders-unconfirmed-container'>
        <div className='orders-content'>
          
          <div className='orders-rest-name'>
            {/*this.props.user.rest.name_and_secondary*/}
            Incoming Orders
          </div>

          {this.state.toast && (
            <OrderToast
              toast={this.state.toast}
              type={this.state.toast.type}
              order={this.state.toast.order}
            />
          )}

          {this.state.unconfirmedOrders.length === 0 && !this.state.toast  && (
            <div className='no-orders-container'>
              <img
                className='no-orders-img'
                src='https://cdn.hngr.co/captain/NoOrdersMan2.png'
              />
              <div className='no-orders-top-text'>
                No Incoming Orders Yet
              </div>
              <div className='no-orders-bottom-text'>
                We'll let you know when there's an order
              </div>
              <div 
                onClick={()=>{
                  this.props.history.push({pathname:'/orders/kitchen'})
                }}
                className='back-to-kitchen'
              >
                <div>Back to In Kitchen Orders</div>
              </div>
            

            </div>
          )}

          {this.state.unconfirmedOrders.map((order)=>(
            <OrderCard
              page='unconfirmed'
              key={order.order_id}
              clock='expires'
              order={order}
              text={this.buttonText(order)}
              loadingText='Accept'
              action={order.fulfill_order_at ? 
                (callback) => this.snoozeOrder(order, callback) :
                (callback)=>this.confirmOrder(order, callback)
              }
            />
          ))}
        </div>
      </div>
    )
  }
}
      

export default withRouterAndRef(
  NewOrdersContextHOC(UserContextHOC(UnconfirmedOrders)) 
)

const Clock = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.848 12.459c.202.038.202.333.001.372-1.907.361-6.045 1.111-6.547 1.111-.719 0-1.301-.582-1.301-1.301 0-.512.77-5.447 1.125-7.445.034-.192.312-.181.343.014l.985 6.238 5.394 1.011z"/></svg>)

