import React, { Component } from "react"
import classnames from "classnames"
import UserContextHOC from "../contexts/UserContextHOC"
import StripeTerminalContextHOC from "../contexts/StripeTerminalContextHOC"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import ButtonWithSpinner from "./ButtonWithSpinner"
import withRouterAndRef  from "../withRouterAndRef"
import Refresh from "./svg/Refresh"
import Logout from "./svg/Logout"
import Plus from "./svg/Plus"
import Minus from "./svg/Minus"
import Update from "./svg/Update"
import ChevronDown from "./svg/ChevronDown"
import StripeCard from "./svg/StripeCard"
import Gears from "./svg/SettingsAdvanced"
import Slider from "../components/Slider"
import { Capacitor, registerPlugin } from '@capacitor/core'
import { LoadValue, SaveValue } from "../helpers/storage"
import Loading from "../Loading/Loading"
import "./Settings.css"
import {
  StripeTerminalPlugin,
  ConnectionStatus,
  DiscoveryMethod
} from 'capacitor-stripe-terminal'
const UpdateAvailablePlugin = registerPlugin('UpdateAvailablePlugin')
const Api = require("../api")


class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isClosedEarlyLoaded:false,
      advancedSettings:false,
      isDesktop:window.innerWidth > 600
    }
    if(this.props.user.rest){
      this.state.pickupPrepTime = this.props.user.rest.pickup_prep_time
      this.state.deliveryPrepTime = this.props.user.rest.delivery_prep_time
      this.state.orderingLink = this.props.user.rest.ordering_link
      this.state.kioskDinerUuid = this.props.user.rest.kiosk_diner_uuid
    }
    if(this.props.user.rest){
      this.setKioskDiner(this.props.user.rest.kiosk_diner_uuid);
      this.setTerminalLocation(this.props.user.rest.stripe_terminal_location_id);
      this.state.loaded = true
    }
    window.m = this
  }

  componentDidMount(){
    this.checkClosedEarly()
  }

  componentDidUpdate(prevProps){
    if(this.props.user.rest && !prevProps.user.rest){
      this.setState({
        pickupPrepTime:this.props.user.rest.pickup_prep_time,
        deliveryPrepTime:this.props.user.rest.delivery_prep_time,
        orderingLink:this.props.user.rest.ordering_link,
        kioskDinerUuid:this.props.user.rest.kiosk_diner_uuid,
        loaded:true
      })
      this.setKioskDiner(this.props.user.rest.kiosk_diner_uuid);
      this.setTerminalLocation(this.props.user.rest.stripe_terminal_location_id);
    }
  }

  checkClosedEarly(cb){
    if(!cb) cb = () => {}

    Api.callApi({
      endpoint:'is-closed-early',
      success:(data)=>{
        this.setState({
          isClosedEarlyLoaded:true,
          pickupClosedEarly:data.pickup_closed_early,
          deliveryClosedEarly:data.delivery_closed_early
        }, cb)
      },
      failure:(err)=>{
        console.log("is closed ealry earror", err)
      }
    })
  }

  toggleCloseEarly(type, callback){
    let endpoint
    let params = {}
    if(type === 'pickup'){
      params['pickup'] = true
      if(this.state.pickupClosedEarly){
        endpoint = 'undo-close-early' 
      } else{
        endpoint = 'close-early'
      }
    }
    
    if(type === 'delivery'){
      params['delivery'] = true
      if(this.state.deliveryClosedEarly){
        endpoint = 'undo-close-early' 
      } else{
        endpoint = 'close-early'
      }
    }

    Api.callApi({
      endpoint:endpoint,
      data:params,
      success:(data)=>{
        this.checkClosedEarly(callback)
        this.props.orders.checkClosedEarly()
      }
    })
  }

  pickupPrepTime(val){
    let time = this.state.pickupPrepTime + val 
    if(time < 5) time = 5
    this.setState({pickupPrepTime:time}, ()=>this.savePrepTime('pickup'))
  }

  deliveryPrepTime(val){
    let time = this.state.deliveryPrepTime + val 
    if(time < 5) time = 5
    this.setState({deliveryPrepTime:time},()=>this.savePrepTime('delivery'))
  }

  savePrepTime(type){
    let params = {}
    if (type === 'pickup'){
      params['default_pickup_time_minutes'] = this.state.pickupPrepTime
    } else{
      params['default_delivery_time_minutes'] = this.state.deliveryPrepTime
    }
    Api.callApi({
      endpoint:'update-prep-time',
      data:params,
      success:()=>this.props.user.loadRest(),
      failure:(err)=>{
        alert(err)
        if (type === 'pickup'){
          params['pickupPrepTime'] = this.props.user.rest.default_pickup_time
        } else{
          params['deliveryPrepTime'] =this.props.user.rest.default_delivery_time 
        }
        this.setState({...params})
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'There was an error updating the prep time' 
          }
        })
      }
    })
  }

  async checkForUpdates(){
    let data = await UpdateAvailablePlugin.checkForUpdate()
    alert(JSON.stringify(data))
    window.location.href = 'https://play.google.com/store/apps/details?id=com.captain.app'
  }

  async setKioskDiner(val){
    console.log('kiosk set diner:', val);
    await SaveValue('kiosk_diner_uuid', val); 
  }

  async setTerminalLocation(val){
    await SaveValue('stripe_terminal_location_id', val)
  }



  render(){
    if(!this.state.loaded){
      return <Loading />
    }

    return (
      <div>
        <div className='orders-content'> 

          <div className='settings-title'>
            Restaurant Management
          </div>
          
          {this.state.isClosedEarlyLoaded && (
            <div className='settings-close-early-container'>
              <div className='settings-row-label'>Turn off/on orders</div>
                <div className='settings-row-label'>
                  You can temporarily stop taking orders for any reason if you need to.
                  You'll be able to switch this back on.
                </div>
              
              <div className='settings-close-early-buttons-container'>
                <div className='settings-close-early-button-container'>
                  <div className='settings-close-early-left'>
                    <div className='settings-close-early-type'>Pickup</div>
                    <div 
                      className={classnames('settings-close-early-status',
                        {'green':!this.state.pickupClosedEarly},
                        {'red':this.state.pickupClosedEarly})}
                    >
                      {!this.state.pickupClosedEarly ? <>OPEN</> : <>CLOSED</>}
                    </div>
                  </div>

                  <ButtonWithSpinner
                    className={classnames('settings-close-early-button',
                      {'settings-close-early-button-turn-off':!this.state.pickupClosedEarly})}
                    text={this.state.pickupClosedEarly ? 'Turn On' : 'Turn Off'}
                    onClick={(cb)=>this.toggleCloseEarly('pickup', cb)}
                  />
                </div>

                <div className='settings-close-early-button-container' style={{marginTop:'25px'}}>
                  <div className='settings-close-early-left'>
                    <div className='settings-close-early-type'>Delivery</div>
                    <div 
                      className={classnames('settings-close-early-status',
                        {'green':!this.state.deliveryClosedEarly},
                        {'red':this.state.deliveryClosedEarly})}
                    >
                      {!this.state.deliveryClosedEarly ? <>OPEN</> : <>CLOSED</>}
                    </div>
                  </div>

                  <ButtonWithSpinner
                    className={classnames('settings-close-early-button',
                      {'settings-close-early-button-turn-off':!this.state.deliveryClosedEarly})}
                    text={this.state.deliveryClosedEarly ? 'Turn On' : 'Turn Off'}
                    onClick={(cb)=>this.toggleCloseEarly('delivery', cb)}
                  />
                </div>
              </div>
            
            
            </div>
          )}
    
          <div className='choose-printer-sub-settig-container'>
            <div className='choose-printer-sub-setting'>
              Default Prep Time
            </div>
            <div className='choose-printer-sub-sub-setting'>
              Give your customers a time estimate for their order.
              This setting also sets quick accept buttons on incoming orders.
            </div>


            {this.state.pickupPrepTime && (
              <>
                <div className='prep-time-row2' style={{marginTop:'20px'}}>
                  <div className='prep-time-left'>
                    <div className='choose-printer-sub-setting'>Pickup</div>
                    <div className='choose-printer-sub-sub-setting'>
                      Prep Time
                    </div>
                  </div>

                  <div className='prep-time-right'>
                    <Minus onClick={()=>this.pickupPrepTime(-5)}/>
                    <input
                      className='prep-time-input'
                      value={this.state.pickupPrepTime}
                    />
                    <div className='prep-time-minutes-label'>Minutes</div>
                    <Plus onClick={()=>this.pickupPrepTime(5)}/>
                  </div>
                </div>

                <div className='prep-time-row2' style={{marginTop:'20px'}}>
                  <div className='prep-time-left'>
                    <div className='choose-printer-sub-setting'>
                      Delivery
                    </div>
                    <div className='choose-printer-sub-sub-setting'>
                      Prep Time
                    </div>
                  </div>

                  <div className='prep-time-right'>
                    <Minus onClick={()=>this.deliveryPrepTime(-5)}/>
                    <input
                      className='prep-time-input'
                      value={this.state.deliveryPrepTime}
                    />
                    <div className='prep-time-minutes-label'>Minutes</div>
                    <Plus onClick={()=>this.deliveryPrepTime(5)}/>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className='settings-rows-container'>
            <div 
              className='settings-row'
              onClick={()=>this.props.history.push({pathname:'/hours'})}
            >
              <div className='settings-row-left'>
                <Clock className='settings-row-icon'/>
                <div className='settings-row-label'>Hours</div>
              </div>
              <ChevronRight className='settings-row-chevron-right'/>
            </div>
          </div>


          <div className='settings-rows-container'>
            <div 
              className='settings-row'
              onClick={()=>this.props.history.push({pathname:'/settings/choose-printer'})}
            >
              <div className='settings-row-left'>
                <Printer className='settings-row-icon'/>
                <div className='settings-row-label'>Printer Settings</div>
              </div>
              <ChevronRight className='settings-row-chevron-right'/>
            </div>
          </div>


          <div className='settings-rows-container'>
            <div 
              className='settings-row'
              onClick={async ()=> {
                  
                  let stat = await this.props.stripeTerminal.terminal.getConnectionStatus()
                  if(stat !== ConnectionStatus.Connected){
                    alert('Please pair the reader before entering Kiosk Mode')
                    return
                  }
 
                  window.location.href = this.state.orderingLink 
              }}
            >
              <div className='settings-row-left'>
                <Tablet className='settings-row-icon'/>
                <div className='settings-row-label'>Kiosk Mode</div>
              </div>
              <ChevronRight className='settings-row-chevron-right'/>
            </div>
          </div>


          <div className='settings-rows-container'>
            <div 
              className='settings-row'
              onClick={()=>this.props.history.push({pathname:'/stripe-pos/home'})}
            >
              <div className='settings-row-left'>
                <StripeCard className='settings-row-icon'/>
                <div className='settings-row-label'>Stripe Terminal Settings</div>
              </div>
              <ChevronRight className='settings-row-chevron-right'/>
            </div>
          </div>

          <div className='advanced-settings-container'>
            <div className='settings-rows-container-no-border'>
              <div 
                className='settings-row'
                onClick={()=>this.setState({advancedSettings:!this.state.advancedSettings})}
              >
                <div className='settings-row-left'>
                  <Gears className='settings-row-icon'/>
                  <div className='settings-row-label'>Advanced Settings</div>
                </div>
                {this.state.advancedSettings  === true ? (
                  <ChevronDown className='settings-row-chevron-right'/>
                ) : (
                  <ChevronRight className='settings-row-chevron-right'/>
                )}
              </div>
            </div>

            {this.state.advancedSettings === true && (
              <>
                <div className='settings-rows-container-no-border'>
                  <div className='advanced-settings-row'>
                    <div className='settings-row-title-subtitle-container'>
                      <div className='choose-printer-sub-setting'>
                        Manually Complete Orders
                      </div>
                      <div className=' choose-printer-sub-sub-setting'>
                        Display readied orders in queue and confirm completion
                      </div>
                    </div>

                    <Slider 
                      set={()=>this.props.user.setShowAwaiting(true)}
                      unset={()=>this.props.user.setShowAwaiting(false)}
                      on={this.props.user.showAwaiting}
                      onWord='Show'
                      offWord='Hide'
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className='settings-rows-container'>
            <div 
              className='settings-row'
              onClick={()=>document.location.reload()}
            >
              <div className='settings-row-left'>
                <Refresh className='settings-row-icon'/>
                <div className='settings-row-label'>Refresh Page (version {window.JS_VERSION})</div>
              </div>
              <ChevronRight className='settings-row-chevron-right'/>
            </div>
          </div>

          <div className='settings-rows-container'>
            <div 
              className='settings-row'
              onClick={()=>this.props.history.push("/tax-exemptions")}
            >
              <div className='settings-row-left'>
                <Tax className='settings-row-icon'/>
                <div className='settings-row-label'>
                  Tax Exempt Diners
                </div>
              </div>
              <ChevronRight className='settings-row-chevron-right'/>
            </div>
          </div>

          
          {/*Capacitor.getPlatform() === 'android' && ( 
            <div className='settings-rows-container'>
              <div 
                className='settings-row'
                onClick={()=>this.checkForUpdates()}
              >
                <div className='settings-row-left'>
                  <Update className='settings-row-icon'/>
                  <div className='settings-row-label'>Check For Updates</div>
                </div>
                <ChevronRight className='settings-row-chevron-right'/>
              </div>
            </div>
          )*/}


          <div className='settings-rows-container'>
            <div 
              className='settings-row'
              onClick={()=>this.props.user.logout()}
            >
              <div className='settings-row-left'>
                <Logout className='settings-row-icon' />
                <div className='settings-row-label'>Logout</div>
              </div>
              <ChevronRight className='settings-row-chevron-right'/>
            </div>
          </div>



        </div>
      </div>
    )
  }
}
      
export default StripeTerminalContextHOC(
  NewOrdersContextHOC(withRouterAndRef(UserContextHOC(Settings))))

const Printer = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 20h-6v-1h6v1zm10-15v13h-4v6h-16v-6h-4v-13h4v-5h16v5h4zm-6 10h-12v7h12v-7zm0-13h-12v3h12v-3zm4 5.5c0-.276-.224-.5-.5-.5s-.5.224-.5.5.224.5.5.5.5-.224.5-.5zm-6 9.5h-8v1h8v-1z"/></svg>
)

const ChevronRight = props =>(
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"/></svg>
)

const Clock = props =>(
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.848 12.459c.202.038.202.333.001.372-1.907.361-6.045 1.111-6.547 1.111-.719 0-1.301-.582-1.301-1.301 0-.512.77-5.447 1.125-7.445.034-.192.312-.181.343.014l.985 6.238 5.394 1.011z"/></svg>
)

const Tablet = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 24c1.104 0 2-.896 2-2v-20c0-1.104-.896-2-2-2h-14c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h14zm-14-3v-18h14v18h-14zm6.5 1.5c0-.276.224-.5.5-.5s.5.224.5.5-.224.5-.5.5-.5-.224-.5-.5z"/></svg>
)
const Tax = props => (<svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M24 24h-24v-2h.998l.009-6h21.993v6h1v2zm-3-6h-18v4h2v-2c0-.552.448-1 1-1s1 .448 1 1v2h2v-2c0-.552.448-1 1-1s1 .448 1 1v2h2v-2c0-.552.448-1 1-1s1 .448 1 1v2h2v-2c0-.552.448-1 1-1s1 .448 1 1v2h2v-4zm2-3h-21.991l.003-2h3.988v-3h2v3h2v-3h2v3h2v-3h2v3h2v-3h2v3h4v2zm-8-13h-3v1c2.966 0 6.158 1.979 7 6h-14c.547-3.78 3.638-5.827 6-6v-3h4v2zm.954 5c-.88-1.1-2.229-2-3.954-2-1.96 0-3.264.837-4.086 2h8.04z"/></svg>)
