import React, { Component } from 'react';
import UserContextHOC from "./UserContextHOC"
import { registerPlugin } from '@capacitor/core'
import withRouterAndRef from "../withRouterAndRef"

const PlayNoisePlugin = registerPlugin('PlayNoisePlugin');
const PrintReceiptPlugin = registerPlugin('PrintReceiptPlugin');



const Api = require("../api")

export const NewOrdersContext = React.createContext()

class NewOrdersProvider extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      lastOrderId:null,
      unconfirmedOrders:[],
      snoozedOrders:{},
      awaitingOrders:{
        'pickup':[],
        'delivery':[]
      },
      activeOrders:{
        'pastdue':[],
        'zero-15':[],
        'fifteen-30':[],
        'thirty-45':[],
        '45-plus':[]
      },
      historyOrders:[],
      getHistoryOrders:(cb)=>this.getHistoryOrders(cb),
      checkForOrders:()=>this.checkForOrders(false),
      checkForSnoozedOrders:()=>this.checkForSnoozedOrders(false),
      checkForActiveOrders:()=>this.checkForActiveOrders(false),
      checkForAwaitingOrders:()=>this.checkForAwaitingOrders(false),
      checkClosedEarly:()=>this.checkClosedEarly(false),
    }
    this.ordersToPrint = []
    this.recordPrinting = this.recordPrinting.bind(this)
    this.firstPass = true
    window.new = this
  }

  componentDidMount(){
    this.mounted = true
    this.checkIfApiKeyLoaded()
    window.addEventListener('receiptPrinted', this.recordPrinting)
  }

  componentWillUmount(){
    this.mounted = false
    window.removeEventListener('receiptPrinted', this.recordPrinting)
  }

  checkIfApiKeyLoaded(){
    if(window.API_KEY){
      this.doLoopyStuff()
    } else{
      setTimeout(()=>this.checkIfApiKeyLoaded(), 100)
    }
  }

  doLoopyStuff(){
    this.checkForOrders(true)
    this.checkForSnoozedOrders(true)
    this.checkForActiveOrders(true)
    this.checkForAwaitingOrders(true)
    this.checkClosedEarly(true)
    this.playAlertLoop()
  }

  playAlertLoop(){
    if(this.state.unconfirmedOrders.length){
      this.playAlert()
    }
    setTimeout(()=>this.playAlertLoop(), 15000)
  }

  playAlert(){
    try{
      PlayNoisePlugin.playUnconfirmedSound({}).catch(()=>{})
    } catch(err){
      let a = 'do nothing'

    }
  }

  // this component is responsbiel for writing to the print log
  recordPrinting(data){
    let params = {
      source:data.source,
      order_id:data.order_id,
      success:data.success,
      error_message:data.error_message
    }
    if(data.success == 0 ){
      if(data.source === 'polling') {
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'Failed to print incoming order.'
          }
        })
      }
      if(data.source === "reprint") {
        this.props.history.push({
          pathname:this.props.location.pathname +'/error-modal',
          state:{
            message:'Failed to reprint receipt.'
          }
        })
      }
    }
    Api.callApi({
      endpoint:'print-log',
      data:params
    })
  }
  /*

  orders come in ascending order so 54, 65, 87

  If last_order_id is null, then set last_order_id... that'it

  If last_order_id is NOT null, then we need to print all orders
  that are greater than the last_order_id AND that are not in the 
  current need-to-print-queue 
  */
  checkForOrdersToPrint(data){
    // if it's the first load and theres NO orders, then set 
    // the last OrderId to -1 
    if(this.firstPass){
      this.firstPass = false
      if(!data.unconfirmed_orders.length){
        this.lastOrderId = 1
        return
      }
    }

    // we just loaded up the site, dont print all the orders
    if(!this.lastOrderId){
      if(data.last_order_id){
        this.lastOrderId = data.last_order_id
        return
      } else{
        console.log("else no data.last_order_id")
      }
    } else{
      for(let i in data.unconfirmed_orders){
        let order = data.unconfirmed_orders[i]
        if(order.order_id > this.lastOrderId){
          this.printOrder(order.order_id)
          this.lastOrderId = order.order_id
        }
      }
    }
  }

  printOrder(order_id){
    if(this.props.user.rest.fax){
      return
    }

    if(!this.props.user.printer || !this.props.user.printer.ipAddress){
      return
    }
    if(this.props.user.printTrigger !== 'transmit'){
      return
    }

    PrintReceiptPlugin.printReceipt({
      order_id:order_id.toString(),
      api_key:window.API_KEY,
      source:'polling',
      ip_address:this.props.user.printer.ipAddress,
      copies:this.props.user.receiptCopies.toString()
    })
    
  }

  checkClosedEarly(setTimer){
    if(!this.props.user.rest){
      if(setTimer){
        setTimeout(()=>this.checkClosedEarly(setTimer), 1000)
      }
      return
    }
    Api.callApi({
      endpoint:'is-closed-early',
      success:(data)=>{
        this.setState({
          pickupClosedEarly:data.pickup_closed_early,
          deliveryClosedEarly:data.delivery_closed_early
        }, ()=>{
            if(setTimer){
              setTimeout(()=>this.checkClosedEarly(), 60000)
            }
          })
      },  
    })  
  }

  checkForOrders(setTimer){
    if(!this.props.user.rest){
      if(setTimer){
        setTimeout(()=>this.checkForOrders(setTimer), 100)
      }
      return
    }
    Api.callApi({
      endpoint:'check_for_new_orders',
      success:(data)=>{
        this.setState({unconfirmedOrders:data.unconfirmed_orders}, ()=>{ 
          this.checkForOrdersToPrint(data)
          if(setTimer && this.mounted){
            setTimeout(()=>this.checkForOrders(true),10000)
          }
        })
      },
      failure:()=>{
        if(setTimer && this.mounted){
          setTimeout(()=>this.checkForOrders(true), 10000)
        }
      }
    })
  }


  checkForActiveOrders(setTimer){
    if(!this.props.user.rest){
      if(setTimer){
        setTimeout(()=>this.checkForActiveOrders(setTimer), 100)
      }
      return
    }
    Api.callApi({
      endpoint:'active_orders',
      success:(data)=>{
        if(setTimer && this.mounted){
          setTimeout(()=>this.checkForActiveOrders(setTimer), 30000)
        }
        this.setState({activeOrders:data.orders, activeLoaded:true})
      },
      failure:()=>{
        if(setTimer && this.mounted){
          setTimeout(()=>this.checkForActiveOrders(setTimer), 30000)
        }
        console.log('Error checking for new orders')
      }
    })
  }

  checkForSnoozedOrders(setTimer){
    if(!this.props.user.rest){
      if(setTimer){
        setTimeout(()=>this.checkForSnoozedOrders(setTimer), 100)
      }
      return
    }
    Api.callApi({
      endpoint:'snoozed-orders',
      success:(data)=>{
        if(setTimer && this.mounted){
          setTimeout(()=>this.checkForSnoozedOrders(setTimer), 10000)
        }
        this.setState({snoozedOrders:data.days, snoozedLoaded:true})
      },
      failure:()=>{
        if(setTimer && this.mounted){
          setTimeout(()=>this.checkForSnoozedOrders(setTimer), 10000)
        }
        console.log('Error checking for snoozed orders')
      }
    })
  }

  checkForAwaitingOrders(setTimer){
    if(!this.props.user.rest || !this.props.user.showAwaiting){
      if(setTimer){
        setTimeout(()=>this.checkForAwaitingOrders(setTimer), 100)
      }
      return
    }
    Api.callApi({
      endpoint:'/api/tablet/v2/waiting-for-completion-orders',
      success:(data)=>{
        if(setTimer && this.mounted){
          setTimeout(()=>this.checkForAwaitingOrders(setTimer), 30000)
        }
        this.setState({awaitingOrders:data})
      },
      failure:()=>{
        if(setTimer && this.mounted){
          setTimeout(()=>this.checkForAwaitingOrders(setTimer), 30000)
        }
        console.log('Error checking for new orders')
      }
    })
  }

  getHistoryOrders(callback){
    Api.callApi({
      endpoint:'history',
      success:(data)=>{
        this.setState({historyOrders:data.orders}, callback)
      },
    })  
  }

  render() {
    return  (
      <NewOrdersContext.Provider value={this.state}>
        {this.props.children}
      </NewOrdersContext.Provider>
    )
  }
}

export default withRouterAndRef(UserContextHOC(NewOrdersProvider));
