import React, { Component } from "react"
import classnames from "classnames"
import UserContextHOC from "../contexts/UserContextHOC"
import StripeTerminalContextHOC from "../contexts/StripeTerminalContextHOC"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import {registerPlugin} from '@capacitor/core';
import Loading from "../Loading/Loading"
import Warning from "../orders/svg/Warning"
import Refresh from "../orders/svg/Refresh"
import "./Choose.css"
import {
  StripeTerminalPlugin,
  DiscoveryMethod
} from 'capacitor-stripe-terminal'

const Api = require("../api")


class ChooseTerminal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:true
    }
    window.choose = this
  }

  componentDidMount(){
    this.checkLocationPermission()
  }

  async checkLocationPermission(){
    // check if permission is required
    let response = await StripeTerminalPlugin.checkPermissions();
    if (response.location === 'prompt') {
      // if it is required, request it
      response = await StripeTerminalPlugin.requestPermissions();
      if (response.location !== 'granted') {
        alert("Location permissions are required to use a stripe terminal")
        this.props.history.replace("/home")
      } else{
        this.getReaders()
      }   
    } else if (response.location === 'granted'){
        this.getReaders()
    }   
  }


  async getReaders(){
    let that = this
    let sub = this.props.stripeTerminal.terminal
      .discoverReaders({
        simulated: false,
        discoveryMethod: DiscoveryMethod.BluetoothProximity
      })
      .subscribe(readers => {
        if (readers.length) {
          that.setState({readers:readers, loading:false})
        }
      })
  }

  postToSlack(msg){
    Api.callApi({
      endpoint:'/stripe-pos/slack-message',
      method:'post',
      data:{
        msg:msg
      },  
      success:(data)=>{
      },  
      failure:(err)=>{
      }   
    })  
  }

  async connect(reader){
    const connectionConfig = {
      locationId:this.props.user.rest.stripe_terminal_location_id  
    }

    let that = this
    this.setState({loading:true})
    this.props.stripeTerminal.terminal
      .connectBluetoothReader(reader, connectionConfig)
      .then(connectedReader => {
        that.props.user.saveStripeTerminal(reader.serialNumber)
        that.props.history.replace({
          pathname:'/stripe-pos/connected'
        }) 
        // the reader is now connected and usable
      })

  }

  render(){
    return (
      <div className='orders-content'>
        <div className='choose-reader-title'>
          Available Card Readers
        </div>

        <div className='choose-reader-subtitle'>
          Turn on Stripe Terminal reader and place near your device. Make sure Bluetooth is enabled. Do not pair in settings.
        </div>

        {this.state.loading === true  ? (
          <Loading/>
        ) : (
          <>
            {this.state.readers.length === 0 ? (
              <div className='choose-reader-no-readers'>
                <Warning />
                <div className='choose-reader-no-reader-text'>
                  We could not find any bluetooth card readers. Please make sure 
                  bluetooth is enabled on the tablet and that a nearby card reader 
                  is powered on and refresh the list.
                </div>
              </div>
            ) : ( 
              <div className='choose-reader-list-container'>
                {this.state.readers.map(r => (
                  <div className='choose-reader-reader'>
                    <div className='choose-reader-name'>{r.serialNumber}</div>
                    <div
                      className='choose-reader-connect'
                      onClick={()=>this.connect(r)}
                    >
                      <div>Connect</div>
                    </div>
                  </div>
                ))}
              </div>
            
            )}


            <div className='choose-reader-refresh-list-container'>
              <div onClick={()=>this.getReaders()} className='choose-reader-refresh-list'>
                <div> Refresh List</div>
                <Refresh />
              </div>
            </div>
          </>
        )}

      </div>
    )
  }

}
      
export default withRouterAndRef(
  StripeTerminalContextHOC(UserContextHOC(ChooseTerminal)))


