import React, { Component } from 'react';
import UserContextHOC from "./UserContextHOC"
import { Capacitor } from '@capacitor/core';
import withRouterAndRef from "../withRouterAndRef"
import LogRocket from 'logrocket';
import {
  StripeTerminalPlugin,
  ConnectionStatus,
  DiscoveryMethod
} from 'capacitor-stripe-terminal'
const Api = require("../api")

export const StripeTerminalContext = React.createContext()

class StripeTerminalProvider extends Component {

  constructor(props){
    super(props)
    this.state = {
    }
    window.poo = ConnectionStatus
  }  

  componentDidMount(){
    this.initTerminal()
    this.connectToTerminalIfReady()
  }

  connectToTerminalIfReady(){
    if(!this.props.user.rest){
      setTimeout(()=>this.connectToTerminalIfReady(), 100)
      return 
    }

    if(!this.props.user.rest.stripe_terminal_location_id){
      return
    }

    if(!this.props.user.stripeTerminalLoaded){
      setTimeout(()=>this.connectToTerminalIfReady(), 100)
      return
    }
    
    if(!this.props.user.stripeTerminal){
      return
    }

    if(!this.terminalInitialized){
      setTimeout(()=>this.connectToTerminalIfReady(), 100)
      return
    }

    this.tryToConnect()
  }

  /*
  async checkIfUserHasTerminalSaved(){
    // if its already connected, do nothing
    // if they have it saved, try to connet

    if(!this.props.user.stripeTerminal) return

    let connected = false
    let sub = this.state.terminal
      .connectionStatus()
      .subscribe(async (connectionStatus) => {
        if(connectionStatus === ConnectionStatus.Connected){
          this.connected = true
          console.log("reader already connected inside context")
        } else if (connectionStatus === ConnectionStatus.Connecting){
          console.log("reader is connecting....")
        } else {
          console.log("reader not connected : " + connectionStatus)

          await this.state.terminal.disconnectReader()

          let stat = await this.state.terminal.getConnectionStatus()
          console.log("reader status", stat)
          
          let a = await this.state.terminal.getConnectedReader()
          console.log("connected reader", a)


          setTimeout((sub)=>this.tryToConnect(sub), 2000)
        }
      })  
  }
  */

  tryToConnect(){
    console.log("readers - inside try to connect, about to call discover")
    let that = this
    this.state.terminal
      .discoverReaders({
        simulated: false,
        discoveryMethod: DiscoveryMethod.BluetoothProximity
      })
      .subscribe(readers => {
        console.log("readers subscribe got hit for discover")
        if (readers.length) {
          console.log("readers.length inside context")
          for(let i in readers){
            if(readers[i].serialNumber == this.props.user.stripeTerminal){
              if(!this.connected){
                const connectionConfig = {
                  locationId: this.props.user.rest.stripe_terminal_location_id 
                }
                that.state.terminal
                  .connectBluetoothReader(readers[i], connectionConfig)
                  .then(connectedReader => {
                    console.log("Reader connected from context!")
                  })
              }
            }
          }
        }
      })
    
  }


  /*
  async checkLocationPermission(){
    // check if permission is required
    let response = await StripeTerminalPlugin.checkPermissions();
    if (response.location === 'prompt') {
      // if it is required, request it
      response = await StripeTerminalPlugin.requestPermissions();

      if (response.location !== 'granted') {
        alert("Location permissions are required to use a stripe terminal")
        this.props.history.replace("/home")
      } else{
        this.checkIfUserHasTerminalSaved()
        //this.checkIfConnected()
      }
    } else if (response.location === 'granted'){
      this.initTerminal()
    }
  }*/

  async initTerminal(){
    this.state.terminal = await StripeTerminalPlugin.create({
      fetchConnectionToken: async () => {
        let url = `${process.env.REACT_APP_FOODCOIN_API_LOCATION}/stripe-pos/create-connection-token`
        const resp = await fetch(url, {
          method: 'POST'
        })
        console.log("fetch resp", resp)
        const data = await resp.json()
        this.terminalInitialized = true
        return data.secret
      },  
      onUnexpectedReaderDisconnect: () => {
        console.log("Reader unexpectedly disconnected")
        this.connectToTerminalIfReady()
        // handle reader disconnect
      }   
    })  
  }
  
  

  // if the StripeTerminal is not logged in, render the <Login />
  render() {
    return  (
      <StripeTerminalContext.Provider value={this.state}>
        {this.props.children}
      </StripeTerminalContext.Provider>
    )
  }
}

export default withRouterAndRef(UserContextHOC(StripeTerminalProvider));
