import React, { Component } from "react"
import classnames from "classnames"
import DesktopSidebar from "../menu/DesktopSidebar"
import UserContextHOC from "../contexts/UserContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import SearchIcon from "../orders/svg/Search"
import Slider from "../components/Slider"
import "./TaxExemptions.css"
const Api = require("../api")
const Phone = require("../helpers/phone")

class TaxExemptions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query:null,
      diners:[],
      isDesktop:window.innerWidth >= 1000,
      taxExemptDiners:[]
    }
    this.inputRef = React.createRef()

    window.tax = this
  }

  componentDidMount(){
    this.loadTaxExemptDiners()
  }

  loadTaxExemptDiners(){
    Api.callApi({
      endpoint:'/api/tablet/tax-exempt-diners',
      method:'get',
      success:(resp)=>{
        this.setState({taxExemptDiners:resp.diners})
      },
      failure:(err)=>{
        alert("Error loading tax exempt diners")
      }
    })
  }

  set(diner_id){
    Api.callApi({
      endpoint:'/api/tablet/tax-exempt-diner-set',
      method:'post',
      data:{diner_id:diner_id},
      success:(resp)=>{
      },
      failure:(err)=>{
        alert("Error setting diner to tax exempt")
        window.location.replace("/tax-exemptions")
      }
    })
  }

  unset(diner_id){
    Api.callApi({
      endpoint:'/api/tablet/tax-exempt-diner-unset',
      method:'post',
      data:{diner_id:diner_id},
      success:(resp)=>{
      },
      failure:(err)=>{
        alert("Error unsetting diner to tax exempt")
        window.location.replace("/tax-exemptions")
      }
    })
  }

  input(query){
    this.setState({query:query, loading:true})
    let searchId = Math.random()
    this.searchId = searchId
    Api.callApi({
      endpoint:'/api/tablet/tax-exempt-diner-search',
      method:'post',
      data:{query:query, searchId:this.searchId},
      success:(resp)=>{
        if(resp.searchId != this.searchId) return
        this.setState({diners:resp.diners, loading:false})
      },
      failure:(err)=>{
        console.log("error searching for diners", err)
      }
    })
  }

  clearSearch(){
    this.setState({
      query:null,
      diners:[]
    })
    if(this.inputRef && this.inputRef.current){
      this.inputRef.current.value = ''
    }
  }

  renderContent(){
    let diners = this.state.query ? this.state.diners : this.state.taxExemptDiners
    return (
      <div>
        <div 
          className='orders-content'
          style={this.state.isDesktop ? {marginTop:'0px'} : {}}
        > 

          <div className='search-bar-container'>
            <SearchIcon className='search-bar-icon'/>
            <input
              ref={this.inputRef}
              value={this.state.query}
              onChange={(e)=>this.input(e.target.value)}
              placeholder='Search Diners'
              className='search-bar-input-search-page search-bar-input'
            />

            <div 
              className='search-cancel'
              onClick={()=>this.clearSearch()}
            >
              Cancel
            </div>
          </div>

          {this.state.loading ? (
            <div
              style={{
                width:'100%', 
                paddingTop:'100px', 
                display:'flex', 
                justifyContent:'center'
              }}
            >
              <img src='https://cdn.hngr.co/supperclub/roller2.gif' alt='spinner' />
            </div>
          ) : (
            <div className='tax-diners-container'>
              {diners.map(d => (
                <div className='tax-exempt-diner' key={d.diner_id}>
                  <div className='tax-exempt-diner-left'>
                    <PersonIcon />
                    <div className='tax-exempt-diner-info'>
                      <div className='tax-exempt-diner-phone'>
                        {Phone.format(d.phone_number)}
                      </div>
                      <div className='tax-exempt-diner-name'>
                        {d.first_name} {d.last_name}
                        <br/>
                        {d.email}
                      </div>
                    </div>
                  </div>
                  <Slider
                    set={()=>this.set(d.diner_id)}
                    unset={()=>this.unset(d.diner_id)}
                    on={d.tax_exempt_id && !d.disabled_at}
                    onWord='On'
                    offWord='Off'
                  />
                </div>
              ))}
            </div>
          )}


        </div>
      </div>
    )
  }

  renderDesktop(){
    return (
      <div className='desktop-wrapper' style={{justifyContent:'flex-start'}}>
        <DesktopSidebar
          menu_uuid={this.menu_uuid}
          page='tax-exemptions'
        />
        <div className=''>
          {this.renderContent()}
        </div>
      </div>
    )
  }

  render(){
    if(this.state.isDesktop) return this.renderDesktop()
    else return this.renderContent()
  }


}
      
export default withRouterAndRef(UserContextHOC(TaxExemptions))

const PersonIcon = props => (<svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.625 4.5C9.14062 4.5 7.125 6.51562 7.125 9C7.125 11.4844 9.14062 13.5 11.625 13.5C14.1094 13.5 16.125 11.4844 16.125 9C16.125 6.51562 14.1094 4.5 11.625 4.5ZM11.625 11.25C10.3828 11.25 9.375 10.2422 9.375 9C9.375 7.75781 10.3828 6.75 11.625 6.75C12.8672 6.75 13.875 7.75781 13.875 9C13.875 10.2422 12.8672 11.25 11.625 11.25ZM11.625 0C5.20312 0 0 5.20312 0 11.625C0 18.0469 5.20312 23.25 11.625 23.25C18.0469 23.25 23.25 18.0469 23.25 11.625C23.25 5.20312 18.0469 0 11.625 0ZM11.625 21C9.29531 21 7.16719 20.1422 5.52656 18.7313C6.225 17.6531 7.42031 16.9219 8.78906 16.8797C9.76406 17.1797 10.6922 17.3297 11.625 17.3297C12.5578 17.3297 13.4859 17.1844 14.4609 16.8797C15.8297 16.9266 17.025 17.6531 17.7234 18.7313C16.0828 20.1422 13.9547 21 11.625 21ZM19.2516 17.0578C18.1078 15.5859 16.3406 14.625 14.325 14.625C13.8469 14.625 13.1062 15.075 11.625 15.075C10.1484 15.075 9.40312 14.625 8.925 14.625C6.91406 14.625 5.14688 15.5859 3.99844 17.0578C2.90156 15.525 2.25 13.65 2.25 11.625C2.25 6.45469 6.45469 2.25 11.625 2.25C16.7953 2.25 21 6.45469 21 11.625C21 13.65 20.3484 15.525 19.2516 17.0578Z" fill="#757575"/>
</svg>)
