import React, { Component } from "react"
import ButtonWithSpinner from "./ButtonWithSpinner"
import withRouterAndRef from "../withRouterAndRef"
import * as Sentry from "@sentry/browser";
import Warning from "./svg/Warning"
import "./UpdateModal.css"
import { registerPlugin } from '@capacitor/core'
const UpdatePlugin = registerPlugin('UpdatePlugin');


class UpdateModal extends React.Component {
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef()
    this.listenForJava = this.listenForJava.bind(this)
  }

  componentDidMount(){
    window.addEventListener('updateDownloaded', this.listenForJava)
  }

  componentWillUnmount(){
    window.removeEventListener("updateDownloaded", this.listenForJava)
  }

  listenForJava(){
    if(this.buttonRef && this.buttonRef.current){
      this.buttonRef.current.setState({loading:false})
    }
  }


  render() {
    return  (
      <div 
        className='modal-background' 
        onClick={
          (e)=>{
            e.stopPropagation()
            this.props.history.goBack() 
          }
        }>
        <div className='update-modal-container'>
          <Warning className='update-modal-warning' />
          <div className='update-modal-something-went-wrong'>
            There is an update for your Captain app available. It is highly
            reccomended that you update the app.
          </div>
          <ButtonWithSpinner
            ref={this.buttonRef}
            stopPropagation={true}
            className='update-modal-back-to-kitchen-button'
            text='Update App'
            spinnerColor='white'
            onClick={(callback)=>{
              UpdatePlugin.update()
            }}
          />
        </div>
      </div>
    )
  }
}

export default withRouterAndRef(UpdateModal)
