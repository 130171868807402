import React, { Component } from "react"
import classnames from "classnames"
import UserContextHOC from "../contexts/UserContextHOC"
import StripeTerminalContextHOC from "../contexts/StripeTerminalContextHOC"
import NewOrdersContextHOC from "../contexts/NewOrdersContextHOC"
import withRouterAndRef  from "../withRouterAndRef"
import { registerPlugin } from '@capacitor/core'
import CheckmarkCircle from "../orders/svg/CheckmarkCircle"
import Loading from "../Loading/Loading"
import "./Connected.css"
import {
  StripeTerminalPlugin,
  ConnectionStatus
} from 'capacitor-stripe-terminal'

const Api = require("../api")


class TerminalConnected extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading:true
    }
  }

  componentDidMount(){
    this.getConnectedReader()
    /*
    this.getConnectedReader().catch(err =>{
      this.postToSlack(`Error connecting to reader inside already-connected-settings-page (${err.message$})`)
    })
    */
  }

  postToSlack(msg){
    Api.callApi({
      endpoint:'/stripe-pos/slack-message',
      method:'post',
      data:{
        msg:msg
      },  
      success:(data)=>{
      },  
      failure:(err)=>{
      }   
    })  
  }


  async getConnectedReader(){
    this.setState({loading:true})
    let connectionSub = this.props.stripeTerminal.terminal
      .connectionStatus()
      .subscribe(connectionStatus => {
        if(connectionStatus === ConnectionStatus.Connected){
          console.log('connected inside terminal connected')
          this.setState({loading:false})
        } else{
          console.log('reader not connected inside terminal-connected')
          this.setState({loading:true})
        }
      })
    // TODO show a page to try and reset this connection?
  }

  async disconnect(){
    await this.props.stripeTerminal.terminal.disconnectReader()
    this.props.user.disconectStripeTerminal( ()=> { 
      alert('Disconnected')
      this.props.history.replace({
        pathname:'/stripe-pos/home'
      })
    })
  }

  render(){
    if(this.state.loading) {
      return (
        <div>
          <Loading/>
        </div>
      )
    }
    return (
      <div>
        <div className='terminal-connected'>
          <CheckmarkCircle />
          <div> Terminal Sucessfully Connected </div>
        </div>
        <div className='orders-content'>
          <div className='choose-reader-title'>
            Stripe Terminal Settings
          </div>
          
          <div className='choose-reader-reader'>
            <div className='choose-reader-name'>{this.props.user.stripeTerminal}</div>
            <div
              className='choose-reader-disconnect'
              onClick={()=>this.disconnect()}
            >
              <div>Disconnect</div>
            </div>
          </div>

        </div>
      </div>
    )
  }

}
      
export default withRouterAndRef(
  StripeTerminalContextHOC(UserContextHOC(TerminalConnected)))


