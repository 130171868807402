import React from "react"
import { DndProvider, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import * as Sentry from "@sentry/browser"
import { render } from "react-dom"
import { BrowserRouter, Route } from "react-router-dom"
import classnames from "classnames"
import ErrorBoundary from "./ErrorBoundary"
import UserProvider from "./contexts/UserContext"
import ToastProvider from "./contexts/ToastContext"
import ModifierModalProvider from "./contexts/ModifierModalContext"
import HeartbeatProvider from "./contexts/HeartbeatContext"
import HeartbeatContextHOC from "./contexts/HeartbeatContextHOC"
import StripeTerminalProvider from "./contexts/StripeTerminalContext"
import NewOrdersProvider from "./contexts/NewOrdersContext"
import AdminSwitchRests from "./admin/SwitchRests"
import MemberSwitchRests from "./login/MemberSwitchRest"
import OrderNavigationPills from "./orders/OrderNavigationPills"
import OrdersUnconfirmed from "./orders/Unconfirmed"
import OrderModal from "./orders/OrderModal"
import OrderCancel from "./orders/Cancel"
import OrderAdjust from "./orders/Adjust"
import OrderHistory from "./orders/OrderHistory"
import ChoosePrinter from "./orders/ChoosePrinter"
import OrdersScheduled from "./orders/Scheduled"
import OrderSearch from "./orders/Search"
import OrdersActive from "./orders/ActiveOrders"
import OrdersAwaiting from "./orders/Awaiting"
import Footer from "./Footer"
import LoginOwner from "./login/LoginOwner"
import LoginEmployee from "./login/LoginEmployee"
import LoginChooseType from "./login/LoginChooseType"
import EnterCode from "./login/EnterCode"
import EnterCodeEmployee from "./login/EnterCodeEmployee"
import ChooseRest from "./login/ChooseRest"
import Settings from "./orders/Settings"
import ErrorModal from "./orders/ErrorModal"
import UpdateModal from "./orders/UpdateModal"
import withRouterAndRef from "./withRouterAndRef"
import Menus from "./menu/Menus"
import Menu from "./menu/Menu"
import MenuCategories from "./menu/MenuCategories"
import MenuModifiers from "./menu/MenuModifiers"
import ModifierClass from "./menu/ModifierClass"
import MenuSearch from "./menu/Search"
import ConfirmModifierClassChangeModal from "./menu/ConfirmModifierClassChangeModal"
import ModifierClassEdit from "./menu/ModifierClassEdit"
import MobileMenu from "./menu/MobileMenu"
import MenuItem from "./menu/MenuItem"
//import ModifierGroupModal from "./menu/ModifierGroupModal"
import AddListModal from "./menu/AddListModal"
import MenuItemEdit from "./menu/MenuItemEdit"
import MenuSectionEdit from "./menu/SectionEdit"
import DeactivateModal from "./menu/DeactivateModal"
import ArchiveModal from "./menu/ArchiveModal"
import CreateModifierModal from "./menu/CreateModifierModal"
import "./app.css"
import Header from "./orders/HeaderNew"
import ScrollIntoView from "./ScrollIntoView"
//import ConnectPOS from "./POS/ConnectPOS"
import StripePOSHome from "./stripe-pos/Home"
import StripePOSChoose from "./stripe-pos/ChooseTerminal"
import StripePOSConnected from "./stripe-pos/TerminalConnected"
import StripePOSCreateCharge from "./stripe-pos/CreateCharge"
import CardCryptoSelector from "./stripe-pos/CardCryptoSelector"
import SolanaPay from "./crypto/SolanaPay"
import TaxExemptions from "./tax-exemptions/TaxExemptions"

// member crap
import {Hours, Profile, QR} from "./member/views"
import Finance from "./member/Finance/Finance"

import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications';

const Api = require("./api")

// Object.fromEntries polyfill
var assert = require('assert');
var fromEntries = require('object.fromentries');
var obj = { a: 1, b: 2, c: 3 };
var actual = fromEntries(Object.entries(obj));
assert.deepEqual(obj, actual);
if (!Object.fromEntries) {
	fromEntries.shim();
}
assert.deepEqual(Object.fromEntries(Object.entries(obj)), obj);



if (process.env.REACT_APP_CAPTAIN_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_CAPTAIN_SENTRY_DSN,
    environment: 'prod', 
    beforeSend(event, hint) {
      try {
        event.extra.REST =  window.REST
        return event
      } catch (err) {
        return event
      }   
    },
  })
}

window.JS_VERSION = 75 

class App extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      pathname:'hey'
    }
    this.initPushNotifications()
    this.checkForUpdates()
  }

  componentWillMount(){
    try {
      document.getElementById("loading-container").remove();
    } catch (e) {
      let a = 'do nothing'
    }
  }


  componentDidUpdate(prevProps){
    if(this.state.pathname !== this.props.location.pathname){
      // if the old path was the stripe terminal, make sure thers not a 
      // hanging paymentintent
      if(this.state.pathname === '/stripe-pos/create-order'){
        //if(StripeReaderPlugin){
        //  StripeReaderPlugin.cancel()
        //}
      }
      this.setState({pathname:this.props.location.pathname})
    }   
  }

  componentDidMount(){
    // hack city for iOS mobile and the top bar
    if(
      Capacitor.getPlatform() === 'ios' &&
      window.innerWidth < 600
    ){
      var style = document.createElement('style');
      style.innerHTML = `
        .full-page-card-header{
          top:var(--inset-top) !important;
        }

        .search-bar-container-sticky {
          top: calc(126px + var(--inset-top)) !important; 
        }
      `
      document.head.appendChild(style);
    }
  }

  initPushNotifications(){
    const addListeners = async () => {
      //// On success, we should be able to receive notifications
      await PushNotifications.addListener('registration',
        token => {
          console.log('Push registration success, token: ' + token.value);
          window.PUSH_TOKEN = token.value
        }
      );

      //// Some issue with our setup and push will not work
      await PushNotifications.addListener('registrationError',
        error => {
          alert('Error on registration: ' + JSON.stringify(error));
        }
      );

      //// Show us the notification payload if the app is open on our device
      await PushNotifications.addListener('pushNotificationReceived',
        notification => {
          console.log('Push received: ' + JSON.stringify(notification));
        }
      );

      //// Method called when tapping on a notification
      await PushNotifications.addListener('pushNotificationActionPerformed',
        notification => {
          this.props.history.push({pathname:'/orders/unconfirmed'})
        }
      );

    }


    const registerNotifications = async () => {
      
      //// Request permission to use push notifications
      //// iOS will prompt user and return if they granted permission or not
      //// Android will just grant without prompting
        //PushNotifications.requestPermissions().then( result => {
        //  if (result.granted) {
        //    //alert('granted')
        //    // Register with Apple / Google to receive push via APNS/FCM
        //    //PushNotifications.register();
        //  } else {
        //    // Show some error
        //  }
        //});



      //PushNotifications.register();
      
      
      await PushNotifications.createChannel({
        id: 'PushPluginChannel',
        description: 'PushPluginChannel',
        importance: 4,
        visibility: 1,
        name: 'PushPluginChannel',
      })
      .then(sucess => console.log("channel", "yay"))
      .catch(error => console.error('channel error ', error));


      await PushNotifications.register();
    }

    addListeners();
    registerNotifications();

  }

  
  checkForUpdates(){
    setInterval(()=>{
      Api.callApi({
        endpoint:'get-version',
        success:(data)=>{
          let version = data.js_version
          if(window.JS_VERSION && version > window.JS_VERSION){
            window.location.reload()
          }
        }
      })
    }, 120000)
  }

  render() {

    /*
    return (
        <Route
          path="/"
          key="stripe-pos"
          component={ConnectPOS}
        />
    )
    */


    return (
      <div 
        id='app-container'
        className='app-container'
        style={{
           "--captain-black":"#0B1246",
           "--ink-primary":"#262525",
           "--pure-black":"#262525",
           "--element-grey":"#DFDFDF",
           "--line-grey":"#8C8C8C",
           "--pure-white":"#FCFCFC",
           "--captain-blue":"#3440FF",
           "--error-red":"#D14848",
           "--utility-error-red-hue":"#F3EBEB",
           "--background-white":"#F4F4F4",
           "--helper-text":"#75757",
           "--inset-top":"env(safe-area-inset-top)",
           "--inset-bottom":"env(safe-area-inset-bottom)"
        }}
      >

        <Route
          path="/"
          exact
          key="index"
          render={()=> {
            if(window.innerWidth > 1000){
              this.props.history.replace({pathname:'/menu/menus'})
            } else {
              this.props.history.replace({pathname:'/orders/kitchen'})
            }
          }}
       />

        <Route
          path="/"
          key="header"
          component={Header}
        />

        <Route
          exact
          path="/captain-admin/choose-rest"
          key="captain-admin-chose-rest"
          component={AdminSwitchRests}
        />


        <Route
          exact
          path="/switch-rests"
          key="member-switch-rest"
          component={MemberSwitchRests}
        />

        <Route 
          path="/orders/kitchen" 
          key="orders" 
          //component={OrdersHome}
          render={() => {
            let key = 'key'
            //when "toast" is in this props.history.location.state,
            //that means that we wwant this page to hard reload,
            //so lets give this a random key
            if(
              this.props.history.location.state && 
              this.props.history.location.state.toast
            ){
              key+='toast'
            }
            return (
              <OrdersActive  key={key}/>
            )
          }}
       />

        <Route 
          path="/orders/awaiting" 
          key="orders-awaiting" 
          //component={OrdersHome}
          render={() => {
            let key = 'key'
            //when "toast" is in this props.history.location.state,
            //that means that we wwant this page to hard reload,
            //so lets give this a random key
            if(
              this.props.history.location.state && 
              this.props.history.location.state.toast
            ){
              key+='toast'
            }
            return (
              <OrdersAwaiting  key={key}/>
            )
          }}
       />

        <Route 
          path="/orders/scheduled" 
          key="orders-scheduled" 
          component={OrdersScheduled}
       />

        <Route 
          path="/orders/history" 
          key="orders-history" 
          component={OrderHistory}
       />

        <Route 
          path="/orders/search" 
          key="orders-search" 
          render={() => {
            let key = 'key'
            if(
              this.props.history.location.state && 
              this.props.history.location.state.toast
            ){
              key+='toast'
            }
            return (
              <OrderSearch  key={key}/>
            )
          }}
        />
 

        <Route 
          path="/orders/unconfirmed" 
          key="orders-unconfirmed" 
          //component={OrdersHome}
          render={() => {
            let key = 'key'
            //when "toast" is in this props.history.location.state,
            //that means that we wwant this page to hard reload,
            //so lets give this a random key
            if(
              this.props.history.location.state && 
              this.props.history.location.state.toast
            ){
              key+='toast'
            }
            return (
              <OrdersUnconfirmed  key={key}/>
            )
          }}
       />
        
        <Route path="*/ordermodal*" render={()=> (<OrderModal history={this.props.history} {...this.props} />)} />
        
        <Route 
          path="*/ordermodal/cancel" 
          component={OrderCancel} 
        />

        <Route 
          path="*/ordermodal/adjust" 
          component={OrderAdjust} 
        />

        <Route 
          path="/menu/menus"
          key="menus" 
          component={Menus}
       />

        <Route 
          path="/menu/menu/:menu_uuid"
          key='menu-page'
          component={Menu}
        />

        <Route 
          path="/menu/menu-categories/:menu_uuid"
          key='menu-catetegories-page'
          component={MenuCategories}
        />

        <Route 
          path="/menu/modifiers/:menu_uuid"
          key='menu-page-modifiers'
          component={MenuModifiers}
        />

        <Route 
          path="/menu/modifier-class/:modifier_class_id"
          key='menu-page-modifiers2'
          render={(props) => <ModifierClass key={props.match.params.modifier_class_id} />}
        /> 
        
        <Route
          path="/menu/modifier-class-from-menu-page/:menu_uuid/:modifier_class_id"
          key='menu-page-modifiers3'
          render={(props) => <ModifierClass fromMenu={true} key={props.match.params.modifier_class_id} />}
        /> 

        <Route
          path="/menu/modifier-class-from-menu-item/:menu_uuid/:menu_item_id/:modifier_class_id"
          key='menu-page-modifiers4'
          render={(props) => <ModifierClass fromItem={true} key={props.match.params.modifier_class_id} />}
        /> 
        
        <Route
          path="/menu/modifier-class-from-search/:modifier_class_id"
          key='menu-page-modifiers4'
          render={(props) => <ModifierClass fromSearch={true} key={props.match.params.modifier_class_id} />}
        /> 



        <Route 
          path="/menu/modifier-class-edit/:modifier_class_id"
          key='menu-page-modifierclass-edit'
          render={()=>(<ModifierClassEdit edit={true} />)}
        /> 

        <Route 
          path="/menu/modifier-group/create/:menu_uuid"
          key='menu-page-modifier-cless-create'
          render={()=>(<ModifierClassEdit create={true} />)}
        /> 

        <Route 
          path="/menu/modifier-group-from-new-item/create/:menu_uuid"
          key='menu-page-modifier-cless-create2'
          render={()=>(<ModifierClassEdit create={true} fromNewItem={true}/>)}
        /> 

        <Route 
          path="/menu/mobile-menu/:menu_uuid"
          key='menu-page0-mobile'
          component={MobileMenu}
        />

        <Route 
          path="/menu/menu-item/:menu_uuid/:menu_item_id"
          key="menu-item"
          component={MenuItem}
       />

        <Route 
          path="/menu/search"
          key="menu-search"
          component={MenuSearch}
       />

        <Route 
          path="*/modifier-modal-new-mc"
          key="modifier-group-search-modal3"
          render={()=>(
            <AddListModal page='modifier-class-create'/>
          )}
       />

        <Route 
          path="*/modifier-modal-edit-mc"
          key="modifier-group-search-modal-edit4"
          render={()=>(
            <AddListModal page='modifier-class-edit'/>
          )}
       />

        <Route 
          path="*/modifier-class-confirm-modal"
          key="modifier-class-confirm-modal"
          component={ConfirmModifierClassChangeModal}
       />

        <Route 
          path="*/modifier-group-modal"
          key="modifier-group-search-modal5"
          render={()=>(
            <AddListModal page='modifier-classes'/>
          )}
       />

        <Route 
          path="*/modifier-group-edit-item-desktop-modal"
          key="modifier-group-search-modal6"
          render={()=>(
            <AddListModal page='modifier-class-edit-item-desktop'/>
          )}
       />

        <Route 
          path="*/modifier-group-new-item-modal"
          key="modifier-group-search-modal7"
          render={()=>(
            <AddListModal page='modifier-class-new-item'/>
          )}
       />

        <Route 
          path="*/modifier-modal"
          key="modifier-search-modal2"
          render={()=>(
            <AddListModal page='modifiers'/>
          )}
       />

       <Route 
          path="/menu/menu-item-edit/:menu_item_id"
          key="menu-item-edit"
          render={() => <MenuItemEdit page='edit' /> }
       />

       <Route 
          path="/menu/menu-item-create/:menu_uuid"
          key="menu-item-create2"
          render={() => <MenuItemEdit page='create' /> }
       />

       <Route 
          path="/menu/menu-section-create/:menu_uuid"
          key="menu-item-create3"
          render={() => <MenuSectionEdit page='create' /> }
       />

       <Route 
          path="/menu/menu-section-edit/:menu_category_id"
          key="menu-item-create4"
          render={() => <MenuSectionEdit page='edit' /> }
       />

        <Route 
          path="*/deactivate-modal"
          key="deactivate-modal" 
          component={DeactivateModal}
       />

        <Route 
          path="*/archive-modal"
          key="archive-modal" 
          component={ArchiveModal}
       />

        <Route 
          path="*/create-modifier-modal"
          key="create-modifier-modal5" 
          component={CreateModifierModal}
       />

        <Route
          exact
          path="/settings/choose-printer"
          key="choose-printer-header"
          component={ChoosePrinter}
        />


        <Route
          exact
          path="/settings"
          key="settings-page"
          component={Settings}
        />

        <Route
          path="/"
          key="footer"
          component={Footer}
        />

        <Route
          path="/login"
          exact
          key="login"
          component={LoginChooseType}
        />

        <Route
          path="/login-with-phone"
          exact
          key="login-with-phone"
          render={()=><LoginChooseType page='phone' />}
        />

        <Route
          path="/login-owner"
          exact
          key="login-owner"
          component={LoginOwner}
        />

        <Route
          path="/login-employee"
          exact
          key="login-employee"
          component={LoginEmployee}
        />

        <Route
          path="/login/confirm"
          exact
          key="login-code"
          component={EnterCode}
        />


        <Route
          path="/login/confirm-employee"
          exact
          key="login-code-employee"
          component={EnterCodeEmployee}
        />

        <Route
          path="/login/choose-rest"
          exact
          key="login-choose-rest"
          component={ChooseRest}
        />

        <Route 
          path="*/error-modal" 
          key="error-modal" 
          component={ErrorModal} 
        />

        <Route 
          path="*/app-update" 
          key="error-moda2l" 
          component={UpdateModal} 
        />

        <Route 
          exact key="hours" 
          path="/hours"
          component={Hours}
        />

        <Route 
          exact key="profile" 
          path="/profile"
          component={Profile}
        />
        
        <Route 
          exact key="QR" 
          path="/QR"
          component={QR}
        />

        <Route 
          exact key="finance" 
          path="/finance"
          component={Finance}
        />
        
        <Route
          path="/stripe-pos/home"
          key='stripe-pos-home'
          component={StripePOSHome}
        />

        <Route
          path="/stripe-pos/choose"
          key='stripe-pos-choose'
          component={StripePOSChoose}
        />

        <Route
          path="/stripe-pos/connected"
          key='stripe-pos-connected'
          component={StripePOSConnected}
        />

        <Route
          path="/stripe-pos/create-order"
          key='stripe-pos-create-charge'
          component={StripePOSCreateCharge}
        />


        <Route
          path="/stripe-pos/foodfight"
          key='stripe-pos-create-charge-food-fight'
          component={CardCryptoSelector}
        />

        <Route
          path="/solana-pay"
          key='solana-pay'
          component={SolanaPay}
        />

        <Route 
          path="/tax-exemptions"
          key="tax-exemptions"
          component={TaxExemptions}
         />

      </div>
    )
  }
}

let AppWithRouter = HeartbeatContextHOC(withRouterAndRef(App))

function getDndBackend(){
  if(Capacitor.getPlatform() === 'ios'){
    return TouchBackend 
  } else if (Capacitor.getPlatform() === 'android'){
    return TouchBackend 
  } else {
    return HTML5Backend
  }
}

render(
  <BrowserRouter>
    <ErrorBoundary>
      <HeartbeatProvider>
        <UserProvider>
          <NewOrdersProvider>
            <ToastProvider>
              <ModifierModalProvider>
                <StripeTerminalProvider>
                  <ScrollIntoView>
                    <DndProvider backend={getDndBackend()}>
                      <AppWithRouter/>
                    </DndProvider>
                  </ScrollIntoView>
                </StripeTerminalProvider>
              </ModifierModalProvider>
            </ToastProvider>
          </NewOrdersProvider>
        </UserProvider>
      </HeartbeatProvider>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById("root")
)

