import React, { Component } from "react"
import classnames from "classnames"
import "./OrderCard.css"
import UserContextHOC from "../contexts/UserContextHOC"
import ButtonWithSpinner from "./ButtonWithSpinner"
import withRouterAndRef from "../withRouterAndRef"
import { registerPlugin } from '@capacitor/core'
const PrintReceiptPlugin = registerPlugin('PrintReceiptPlugin');

class DinerDetailsSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile:window.innerWidth < 600,
      isTablet:window.innerWidth >= 600
    }
    this.listenForFinishedPrints = this.listenForFinishedPrints.bind(this)
    this.printButtonRef = React.createRef()
  }

  componentDidMount(){
    window.addEventListener('receiptPrinted', this.listenForFinishedPrints)
  }

  componentWillUnmount(){
    window.removeEventListener("receiptPrinted", this.listenForFinishedPrints)
  }

  listenForFinishedPrints(data){
    if(data.unique_key == this.unique_printing_key){
      if(this.printButtonRef && this.printButtonRef.current){
        this.printButtonRef.current.setState({loading:false})
      }
    }   
  }

  print(cb){
    let key = Math.floor(Math.random() * 100)
    key = key.toString()
    this.unique_printing_key = key
    if(
      this.props.user.printer &&
      this.props.user.printer.ipAddress
    ){
      //if(PrintReceiptPlugin){
        PrintReceiptPlugin.printReceipt({
          order_id:this.props.order.order_id.toString(),
          api_key:window.API_KEY,
          unique_key:key,
          source:'reprint',
          ip_address:this.props.user.printer.ipAddress,
          copies:"1"
        })
      //} else {
      //  ReceiptPrinter.printReceipt({
      //    order_id:this.props.order.order_id.toString(),
      //    api_key:window.API_KEY,
      //    unique_key:key,
      //    source:'reprint',
      //    ip_address:this.props.user.printer.ipAddress,
      //    copies:"1"
      //  })
      //}
    } else {
      cb()
      this.props.history.push({
        pathname:this.props.location.pathname +'/error-modal',
        state:{
          message:'A printer is not set up.'
        }
      })  
    }
  }

  render(){
    return (
      <div {...this.props} className='full-page-card-diner-container'>

        <div className='full-page-card-diner-inner-container'>
          <div className='full-page-card-diner-left'>
            {this.props.order.mmc_student_name ? (
              <div className='full-page-card-diner-name'>
                {this.props.order.mmc_student_name}
                <br/>
                MMC Student ID: {this.props.order.mmc_student_id}
              </div>
            ) : (
              <div className='full-page-card-diner-name'>
                {this.props.order.diner}
              </div>
            )}
            <div className='full-page-card-diner-phone'>
              {this.props.order.diner_phone_formatted}
            </div>
          </div>

          <div className='full-page-card-diner-right'>
            {this.state.isMobile && (
               <ButtonWithSpinner
                  className='full-page-card-tablet-light-button'
                  style={{padding:'0px 10px'}}
                  text={this.props.user.rest && this.props.user.rest.fax ? 'Fax' : 'Print'}
                  svg={<Printer/>}
                  ref={this.printButtonRef}
                  spinnerColor='blue'
                  stopSpinning={this.state.receiptPrinted}
                  onClick={(cb)=>this.print(cb)}
                />
            )}
          </div>
        </div>

        {this.props.order.pickup_or_delivery === 'delivery' && (
          <div className='full-page-card-diner-address-container'>
            {this.props.order.address1} <br/>
            {this.props.order.address2 && (
              <> {this.props.order.address2}  <br/> </>
            )}
            {this.props.order.city}, {this.props.order.state} {this.props.order.zipcode}
          </div>
        )}


        {this.props.order.delivery_instructions && (
          <div className='full-page-card-special-instructions'>
            <div className='full-page-card-special-instructions-header'>
              <Warning className='full-page-card-special-instructions-warning'/>
              <div className='full-page-card-special-instructions-header-text'>
                DELIVERY INSTRUCTIONS
              </div>
            </div>
            <div className='full-page-card-special-instructions-text'>
              {this.props.order.delivery_instructions}
            </div>
          </div>
        )}

      </div> 
    
    )          
  }
}
      
export default withRouterAndRef(UserContextHOC(DinerDetailsSection)) 



const Printer = props => (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16 18h-8v-1h8v1zm-2 1h-6v1h6v-1zm10-14v13h-4v6h-16v-6h-4v-13h4v-5h16v5h4zm-18 0h12v-3h-12v3zm12 10h-12v7h12v-7zm4-8h-20v9h2v-3h16v3h2v-9zm-1.5 1c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z"/></svg>)


const Warning = props => (<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/></svg>)
